// cn.js
const EN = {
    type: "EN",
    lang: "English",


    // 首页字符
    index_1: "Home",
    index_2: "About Us",
    index_3: "Company Profile",
    index_4: "Honorary Qualifications",
    index_5: "Corporate Culture",
    index_6: "Products",
    index_7: "News",
    index_8: "Support",
    index_9: "Project Cooperation",
    index_10: "After Sale",
    index_11: "Download",
    index_12: "TEL",
    index_13: "",

    // 首页-主体字符
    index_21: "NEWS",
    index_22: "Products",
    index_23: "Contact Us",
    index_24: "Phone：182-1008-6720",
    index_24_1: "Phone：182-1008-6720",
    index_25: "Email：zhanghao@hopedeer.com",
    index_25_1: "Email：zhanghao@hopedeer.com",
    index_26: "Address：Shahe Town, Changping District, Beijing",
    index_27: "",
    index_27_1: "",
    index_28: "Beijing Xinfeida Electronic Technology Industrial Development Center F4-4, Baisha Road",
    index_28_1: "Beijing Xinfeida Electronic Technology Industrial Development Center F4-4, Baisha Road",
    index_29: "Open Navigation",
    index_30: "Please select navigation software",
    index_31: "AMAP",
    index_32: "Tencent Maps",
    index_33: "Baidu map",

    index_34: "Beijing Hope Deer Technology Co., Ltd. was established in 2021 and is headquartered at F4-4, Xinfeida Electronic Technology Industrial Development Center, Changping District, Beijing, China. Our company is committed to the research and development of perfluoropolymer breathable membranes, solvent online degassing, one-way valves, connectors, and other fields. Currently, we have launched multiple precision products.",
    index_35_1: "① Perfluoropolymer breathable film",
    index_35_1_1: "Perfluoropolymer breathable film is a new type of film material with high air permeability, high transparency, and thermoplastic properties. It has the highest chemical inertness among all film materials and can withstand almost all chemical corrosion. It has unique properties that cannot be compared to other plastic film materials. Perfluoropolymer breathable membrane, like Teflon AF2400 material, belongs to amorphous perfluorinated resin and can replace Teflon AF2400 material. It is widely used in gas-liquid separation, solvent online degassing and other fields. Support customization of various sizes.",
    index_35_2: "② Film degasser and membrane degassing chamber",
    index_35_2_1: "A special structure chamber made of new perfluoropolymer film materials and other new materials such as PEEK is used. When the liquid flows through the chamber, under the negative pressure of the vacuum pump, the bubbles in the liquid are passed through the film and discharged, achieving online degassing of chemical or biological solvents. The degassing efficiency is high, and the main indicators are far superior to traditional pipeline degassing machines.\n",
    index_35_3: "③ One way valve series",
    index_35_3_1: "Used for one-way transportation of various reagents, it has high sealing, chemical compatibility, and reliability. It uses imported ball/socket groups and can be made of materials such as ruby, sapphire, alumina ceramics, zirconia ceramics, silicon nitride ceramics, etc. All of which have extremely high wear resistance, chemical compatibility, and biological inertness. At the same time, the ball and socket adopt a one-to-one grinding process to ensure excellent sealing. At the same time, internal optional springs can effectively prevent liquid siphoning during pump shutdown.",
    index_35_4: "④ Pipeline connector series",
    index_35_4_1: "Made with precision machining technology, it supports the connection of engineering plastic pipelines with various outer diameters such as PEEK, PTFE, FEP, ETFE, PFA, etc., with higher dimensional accuracy and material density.",
    index_35_99: "We warmly welcome partners from all walks of life to join us and jointly create a new chapter in technological development. If you have any questions or intentions to cooperate with our products or services, please feel free to contact us at any time. Contact number 18210086720.",


    index_36: "Technology",
    index_37: "The company has successively launched various types of products, including vacuum degassing machines, nanofilm degassing chambers, one-way valves, precision machined pipe joints, two-way joints, precision injectors, etc.",
    index_38: "Success cases",
    index_39: "With excellent service and excellent quality, we have received unanimous praise from users! In addition, the company has an efficient R&D team and precise grinding and polishing equipment, which can achieve rapid customization according to user needs and provide overall solutions for fluid components.",
    index_40: "Check out the latest updates on HongLu",
    index_41: "view",
    index_42: "Learn more",

    // 首页-页脚字符
    foot_1: "Why choose HongLu",
    foot_2: "PRODUCT",
    foot_3: "Get The Latest Information",
    foot_4: "Thank you for your attention",
    foot_5: "Yes, I hope HongLu can contact me for news releases, promotions, and events.",
    foot_6: "submit",
    foot_7: "WeChat Account",
    foot_8: "Phone QR",
    foot_9: "Email address cannot be empty",
    foot_10: "The email address does not meet the specifications",
    foot_11: "Please check the user agreement",

    // 手机底部导航栏
    mobile_foot1: "Home",
    mobile_foot2: "Product",
    mobile_foot3: "News",
    mobile_foot4: "Doc",

    // 其他页面
    text1: "Please enter content",
    text2: "loading...",
    text3: "Consult",
    text4: "Corporate",
    text5: "Product",
    text6: "Contact",
    text7: "Notes",
    text8: "We support contact methods such as mobile phone, email, and landline, and we will contact you as soon as possible.",
    text9: "Please select language",
    text10: "Product Details",
    text11: "Documentation",
    text12: "File Type:",

}
export default EN
