<template>
    <nav id="header" class="navbar is-transparent" role="navigation" aria-label="main-navigation">
        <div class="container is-hidden-mobile is-hidden-tablet-only">
            <div class="navbar-brand">
                <router-link class="navbar-item" to="/home" name="&amp;lpos=apps_scodevmw : 0">
                    <img src="../assets/icon_hope_deer.png" width="150px"/>
                </router-link>
            </div>
            <div class="navbar-menu">
                <div role="navigation" class="navbar-end has-text-centered">
                    <router-link class="navbar-item" to="/home" style="font-size: 1.05rem">
                        {{ $t('index_1') }}
                    </router-link>
                    <div class="navbar-item has-dropdown is-hoverable navbar-group-item">
                        <span class="navbar-link">{{ $t('index_2') }}</span>
                        <div class="navbar-dropdown is-boxed">
                            <router-link class="navbar-item" to="/companyInfo?type=GSJJ">
                                {{ $t('index_3') }}
                            </router-link>
                            <router-link class="navbar-item" to="/companyInfo?type=RYZZ">
                                {{ $t('index_4') }}
                            </router-link>
                            <router-link class="navbar-item" to="/companyInfo?type=QYWH">
                                {{ $t('index_5') }}
                            </router-link>
                        </div>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable navbar-group-item" v-if="productTypeList">
                        <span class="navbar-link">{{ $t('index_6') }}</span>
                        <div class="navbar-dropdown is-boxed navbar-group-item">
                            <router-link class="navbar-item" v-for="productType in productTypeList" :to="'/productList?type=' + productType.id">
                                {{ productType.name }}
                            </router-link>
                        </div>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable navbar-group-item">
                        <span class="navbar-link">{{ $t('index_7') }}</span>
                        <div class="navbar-dropdown is-boxed navbar-group-item">
                            <router-link class="navbar-item" v-for="productType in newTypeList"
                                         :to="'/news?type=' + productType.id" :key="productType.id">
                                {{ productType.name }}
                            </router-link>
                        </div>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable navbar-group-item">
                        <span class="navbar-link">{{ $t('index_8') }}</span>
                        <div class="navbar-dropdown is-boxed">
                            <router-link class="navbar-item" to="/item">{{ $t('index_9') }}</router-link>
                            <router-link class="navbar-item" to="/sh">{{ $t('index_10') }}</router-link>
                            <router-link class="navbar-item" to="/fileDownloadList">{{ $t('index_11') }}</router-link>
                        </div>
                    </div>
                    <div class="navbar-item has-dropdown is-hoverable navbar-group-item">
                        <span class="navbar-link">
                            <img src="../assets/earth.svg" width="25px" style="margin-right: 5px">
                            {{ $t('lang') }}
                        </span>
                        <div class="navbar-dropdown is-boxed">
                            <a href="javascript:" @click="changeLang('CN')" class="navbar-item router-link-exact-active router-link-active" aria-current="page">中文</a>
                            <a href="javascript:" @click="changeLang('EN')" class="navbar-item router-link-exact-active router-link-active" aria-current="page">English</a>
                            <a href="javascript:" @click="changeLang('RU')" class="navbar-item router-link-exact-active router-link-active" aria-current="page">Русский язык</a>
                        </div>
                    </div>
                    <div class="navbar-item">
                        <strong style="font-size: 1.25rem;">{{ $t('index_12') }}</strong> <strong style="font-size: 1.55rem;">&nbsp;182-1008-6720</strong>
                    </div>
                    <div class="navbar-item">
                        <div class="wechat-icon">
                            <img class="wechat-logo" src="../assets/weixin_logo.svg" alt="WeChat Logo">
                            <div class="wechat-icon-hover"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>
import axios from '@/utils/axios'
import {getParam} from "@/utils/utils";

export default {
    name: 'navBar',
    data() {
        return {
            productTypeList: null,
            newTypeList: null,
        }
    },
    mounted() {
        axios.get('/jyy/portal/getProductTypeList')
            .then(res => {
                this.productTypeList = res.data
            })

        axios.get('/jyy/portal/getNewsType')
            .then(res => {
                this.newTypeList = res.data
            })
    },
    methods: {
        changeLang(lang) {
            this.$i18n.locale = lang;
            localStorage.setItem("lang", lang)
            location.reload();
        }
    }
}
</script>

<style scoped>

.wechat-icon {
    position: relative;
    width: 35px;
    height: 35px;
}

.wechat-icon .wechat-logo {
    width: 100%;
    height: 100%;
}

.wechat-icon .wechat-icon-hover {
    position: absolute;
    top: calc(100% + 20px); /* 在 weixin_logo.svg 下面 20px 的位置 */
    left: 0;
    background-image: url("../assets/weixin.png"); /* 设置背景图 */
    background-repeat: no-repeat;
    background-size: 200px; /* 背景图片大小 */
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.wechat-icon:hover .wechat-icon-hover {
    width: 200px; /* 设置宽度为 200px */
    height: 200px; /* 设置高度为 200px */
    opacity: 1;
}


</style>
