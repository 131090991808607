<template>
    <div class="main home" style="min-height: 800px">
        <!-- 电脑端页面  -->
        <div style="margin-left: -10px; margin-right: -10px;" v-if="!this.GLOBAL.isMobile">
            <div style="padding-left: 10px; padding-right: 10px;margin-left:12.5%;width: 87.5%">
                <div style="min-height: 800px">
                    <div style="height: 100px">
                    </div>
                    <div style="margin-left: -10px; margin-right: -10px;display: flex;">
                        <div style="padding-left: 1%; padding-right: 1%;width:15%">
                            <div class="lside aos-init aos-animate" data-aos="fade-right" data-aos-delay="300" data-aos-duration="1000">
                                <h2 class="tit">{{$t('index_6')}}</h2>
                                <ul class="iauth">
                                    <li v-for="productType in productTypeList"
                                        @click="$router.push('/productList?type=' + productType.id)">
                                        <a :href="'#/productList?type=' + productType.id"
                                           :title="productType.name"
                                           :class="[(goodsType === productType.id?'act':'') + ' tarA']">
                                            {{ productType.name }}
                                        </a>
                                    </li>
                                </ul>

                                <div class="bdiv">
                                    <h3>{{$t('index_23')}}</h3>
                                    <div class="contact_l"> {{$t('index_24_1')}}<br>
                                        {{$t('index_25_1')}}<br>
                                        {{$t('index_27_1')}}<a target="_blank" style="color: #0a0a0a" href="https://www.amap.com/search?query=北京新飞达电子科技工业发展中心">{{$t('index_28_1')}}</a><br>
                                        <a href="#/item" class="zxun"> {{$t('text3')}} </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style=" padding-right: 10px;width:70%" v-if="product">
                            <div style="min-height: 450px; margin-left: 2%; min-width: 900px; display: flex;">
                                <div class="pic-box">
                                    <PicZoom :url="cp + '/jyy/billFile/getFile?uuid=' + product.showPicUuid" :scale="2"></PicZoom>
                                </div>
                                <div>
                                    <div style="min-height: 180px; padding-left: 40px;padding-top: 40px">
                                        <div style="font-weight: bold;  font-size: 20px; line-height: 30px">
                                            {{ product.productName }} {{ product.productSpec }}
                                        </div>
                                        <div style=" margin-top: 20px; color: #0a0a0a;font-size: 16px; line-height: 30px">
                                            {{ product.showTitle }}
                                        </div>
                                        <div style="flex: 1;height: 60px"></div>
                                        <el-button style="background-color: #d10a29;border-color: #d10a29;"
                                                   class="btn" type="primary" @click="$router.push({ path: '/item', query: { name: product.productName } })">
                                            {{$t('text3')}}
                                        </el-button>
                                    </div>
                                </div>
                                <div style="flex: 1"></div>
                            </div>
                            <div class="products_cont pars" style="min-height: 450px; margin-left: 2%;margin-bottom: 10px; min-width: 900px; ">
                                <ul class="tabUL">
                                    <li @click="tabLiClick(1)" :class="show_detail?'act':''">{{$t('text10')}}</li>
                                    <li @click="tabLiClick(2)" :class="show_file?'act':''">{{$t('text11')}}</li>
                                </ul>
                                <div class="tabCon">
                                    <div class="con" v-if="show_detail">
                                        <div class="editor-content-view" v-html="product.productIntroduction"></div>
                                    </div>
                                    <div class="con" v-if="show_file">

                                        <div style="min-height: 50px; margin: 5px; padding: 10px; border-bottom:1px solid #e4e4e4;" v-for="file in product.billFiles">
                                            <div style="display: flex; align-items: center">
                                                <div style="max-width: 65rem">
                                                    <a target="_blank" :href="[cp + '/jyy/billFile/getFile?uuid=' + file.uuid]">
                                                        <span style="font-size: 1rem;color: #0a0a0a;word-break:break-all;">{{ file.billFileName }}</span>
                                                    </a>
                                                </div>
                                                <div style="flex: 1;"></div>
                                                <div style="color: #404040;min-width: 140px;margin-left: 10px"> {{ file.createTime }}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div style="padding-left: 10px; padding-right: 10%;width:15%">

                        </div>
                    </div>
                </div>
            </div>
            <el-col :span="4"></el-col>
        </div>

        <div v-if="this.GLOBAL.isMobile">
            <div style="margin-top: 70px" v-if="product">
                <div style="margin-bottom: 20px;">
                    <div style="width: 100%">
                        <img :src="cp + '/jyy/billFile/getFile?uuid=' + product.showPicUuid"  >
                    </div>
                    <div>
                        <div style="padding-left: 20px;padding-top: 10px">
                            <div style="font-weight: bold;  font-size: 20px; line-height: 20px">
                                {{ product.productName }} {{ product.productSpec }}
                            </div>
                            <div style=" margin-top: 20px; color: #0a0a0a;font-size: 16px; line-height: 16px">
                                {{ product.showTitle }}
                            </div>
<!--                            <div style="flex: 1;height: 10px"></div>
                            <el-button style="background-color: #d10a29;border-color: #d10a29;float: right;margin-right: 20px"
                                       class="btn" type="primary" @click="$router.push({ path: '/item', query: { name: product.productName } })">
                                {{$t('text3')}}
                            </el-button>-->
                        </div>
                    </div>
                </div>
                <div class="products_cont" style="min-height: 350px; margin-left: 2%;margin-right: 2%; padding: 1rem 0 2rem">
                    <ul class="tabUL">
                        <li @click="tabLiClick(1)" :class="show_detail?'act':''">产品详情</li>
                        <li @click="tabLiClick(2)" :class="show_file?'act':''">产品文档</li>
                    </ul>
                    <div class="tabCon">
                        <div class="con" v-if="show_detail">
                            <div class="editor-content-view" v-html="product.productIntroduction"></div>
                        </div>
                        <div class="con" v-if="show_file">
                            <div style="min-height: 50px; margin: 5px; padding: 10px; border-bottom:1px solid #e4e4e4;" v-for="file in product.billFiles">
                                <div style="">
                                    <a target="_blank" :href="[cp + '/jyy/billFile/getFile?uuid=' + file.uuid]">
                                        <span style="font-size: 1rem;color: #0a0a0a;">{{ file.billFileName }}</span>
                                        <span style="color: #404040;min-width: 140px;margin-left: 10px"> {{ file.createTime }}</span>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                <div style="height: 100px"></div>
            </div>

        </div>
    </div>
</template>

<script>
import {getParam} from '@/utils/utils'
import axios from '@/utils/axios'
import PicZoom from "@/components/PicZoom.vue";

export default {
    components: {PicZoom},
    data() {
        return {
            cp: this.GLOBAL.cp,
            page: 1,
            pageSize: 8,
            total: 0,
            searchValue: '',
            goodsType: '',
            productTypeList: [],
            product: null,
            show_detail: true,
            show_file: false,
        }
    },
    mounted() {
        this.goodsType = getParam('type')
        axios.get('/jyy/portal/getProductTypeList')
            .then(res => {
                this.productTypeList = res.data
            })
        axios.get('/jyy/portal/getProductDetail?id=' + getParam('id'))
            .then(res => {
                this.product = res.data.data
                this.goodsType = res.data.data.goodsType
            })
    },
    methods: {
        tabLiClick: function (type) {
            this.show_detail = type === 1;
            this.show_file = type === 2;
        }

    },
}
</script>


<style scoped>


.pic-box {
    width: 430px;
    height: 430px;
    border: 1px solid #eee;
}


.pro_zx {
    display: inline-block;
    color: #fff;
    border-color: #d10a29;
    font-size: .8rem;
    text-align: left;
    background-color: #d10a29;
    text-indent: 2em;
    line-height: 2rem;
    border-radius: 2rem;
    margin: 1.5rem 0
}


.lside {
    float: left;
    position: relative;
    width: 100%;
    margin-right: 2rem;
    z-index: 300
}


.lside h2 {
    font-size: 1.2rem;
    color: #fff;
    background-color: #d74339;
    text-align: left;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 3.4;
    text-indent: 1em;
    background-repeat: no-repeat;
    background-position: 80% center;
    background-size: auto auto
}

@media (max-width: 768px) {
    .lside h2 {
        font-size: 1.09090909rem
    }
}

.lside .iauth {
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-top: none
}

.lside .iauth li {
    position: relative;
    border-bottom: 1px solid #ddd
}

.lside .iauth li:last-child {
    border: none
}

.lside .iauth a {
    text-align: left;
    font-size: 1.09rem;
    color: #333;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 3.2;
    text-indent: 1.2em;
    padding-right: 1em
}

@media (max-width: 768px) {
    .lside .iauth a {
        font-size: .94444444rem
    }
}

.lside .iauth a::after {
    display: block;
    font-weight: 700;
    content: ">";
    z-index: 5;
    position: absolute;
    right: 10px;
    top: 0
}

.lside .iauth a.act, .lside .iauth a:hover {
    color: #d74339;
    background-color: #f6f6f6;
    border-left: 4px solid #d74339
}

.lside .bdiv {
    border: 1px solid #ddd;
    margin-top: 2rem;
    padding: 0 .8rem .8rem
}

.lside h3 {
    font-size: 1.2rem;
    color: #d74339;
    font-weight: 700;
    line-height: 2.6;
    border-bottom: 1px solid #ddd
}

@media (max-width: 768px) {
    .lside h3 {
        font-size: 1.09090909rem
    }
}

.lside .contact_l {
    padding-top: 1em;
    line-height: 2;
    padding-left: 5px;
    word-wrap: break-word;
}

@media (max-width: 768px) {
    .lside {
        width: 100%;
        float: none
    }

    .lside > * {
        display: none
    }

    .lside .iauth {
        display: none;
        flex-flow: row wrap;
        justify-content: space-between;
        width: 100%
    }

    .lside .iauth li {
        flex: 1;
        flex: 1 1 8em;
        text-align: center;
        margin-right: 3%
    }

    .lside .iauth a {
        padding: 0;
        line-height: 2.7em
    }

    .lside .iauth a:after {
        display: none
    }
}

.listit {
    border-bottom: 1px solid #ddd;
    overflow: visible;
    margin-bottom: 2rem;
    text-align: center
}

.listit h2 {
    font-size: 1.2rem;
    font-weight: 700;
    color: #d10a29;
    display: inline-block;
    position: relative;
    background-color: #fff;
    margin-bottom: -2px;
    line-height: 3.4
}

@media (max-width: 768px) {
    .listit h2 {
        font-size: 1.09090909rem
    }
}

.listit h2::after {
    display: block;
    background-color: #d10a29;
    content: " ";
    height: 3px;
    width: 60%;
    z-index: 5;
    position: absolute;
    left: 20%;
    bottom: 0
}


.products_cont {
    border: 1px solid #ddd;
    padding: 1rem 1.1rem 2rem
}

.products_cont .tabUL {
    font-size: 0;
    border-bottom: 1px solid #ddd;
    margin-bottom: 1.5rem
}

.products_cont .tabUL li {
    display: inline-block;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    padding: 0 2em;
    line-height: 3;
    border: 1px solid #ddd;
    border-bottom: none
}

@media (max-width: 640px) {
    .products_cont .tabUL li {
        font-size: 13px
    }
}

.products_cont .tabUL li.act {
    background-color: #d10a29;
    color: #fff;
    position: relative;
    border-color: #d10a29;
    z-index: 5;
    margin-right: -1px
}
</style>
