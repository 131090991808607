<template>
    <div class="main home" style="min-height: 800px">
        <!-- 电脑端页面  -->
        <el-row :gutter="20" v-if="!this.GLOBAL.isMobile">
            <el-col :span="19" :offset="4">
                <div style="min-height: 800px">
                    <el-row :gutter="16">
                        <el-col :span="12" :offset="4">
                            <div style="margin: 35px">
                                <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                                    <el-button
                                        slot="append"
                                        icon="el-icon-search"
                                        @click="initPageList()"
                                    ></el-button>
                                </el-input>
                            </div>
                        </el-col>
                    </el-row>
                    <el-row type="flex" class="row-bg">
                        <el-col :span="20">
                            <div style="min-height: 700px">
                                <a v-for="( product, index) in productPageList" style="text-decoration:none" target="_blank" :href="['#/news/detail?id=' + product.id]">
                                    <div v-if="index === 0" style="border-bottom:1px solid #e4e4e4;" class="parentDiv">
                                        <el-row>
                                            <el-col :span="7">
                                                <img alt="" :src="[cp + '/jyy/billFile/getFile?uuid=' + product.titlePicture]"
                                                     style="max-width: 100%; max-height: 350px;margin: 10px;"/>
                                            </el-col>
                                            <el-col :span="13">
                                                <div style="min-height: 180px; padding-left: 20px;padding-top: 20px">
                                                    <div class="hoverColor" style="font-weight: bold; font-size: 20px;">
                                                        {{ product.newsTitle }} <img class="qqcom-recIcon" src="https://vfiles.gtimg.cn/wupload/inews_search.daily_hot_tags/20220309_ra2buuxqdna.png">
                                                    </div>
                                                    <div style=" margin-top: 20px; color: #0a0a0a;font-size: 16px;">
                                                        {{ product.newsDigest }}
                                                    </div>
                                                    <div style="flex: 1"></div>
                                                </div>
                                            </el-col>
                                            <el-col :span="4">
                                                <div class="rightDate">
                                                    <div style="margin-left:15px;font-size: 40px;color: #d10a29;line-height: 1;font-weight: 100;">NEW</div>
                                                    <div style="margin-left:18px;font-size: 16px;color: #0a0a0a;line-height: 1.5;">{{ shortTimeDot(product.createTime) }}</div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div v-if="index !== 0" style="border-bottom:1px solid #e4e4e4;">
                                        <el-row type="flex" class="row-bg">
                                            <el-col :span="5">
                                                <img alt="" :src="[cp + '/jyy/billFile/getFile?uuid=' + product.titlePicture]"
                                                     style="max-width: 100%; max-height: 250px;padding: 10px;"/>
                                            </el-col>
                                            <el-col :span="15">
                                                <div style="min-height: 180px; padding-left: 20px;padding-top: 20px">
                                                    <div class="hoverColor" style="font-weight: bold;  font-size: 20px;">
                                                        {{ product.newsTitle }}
                                                    </div>
                                                    <div style=" margin-top: 20px; color: #0a0a0a;font-size: 16px;">
                                                        {{ product.newsDigest }}
                                                    </div>
                                                    <div style="flex: 1"></div>
                                                </div>
                                            </el-col>
                                            <el-col :span="4">
                                                <div class="rightDate">
                                                    <div style="margin-left:15px;font-size: 40px;color: #0a0a0a;line-height: 1;font-weight: 100;">{{ dayDot(product.createTime) }}</div>
                                                    <div style="margin-left:15px;font-size: 16px;color: #0a0a0a;line-height: 1.5;">{{ monthTimeDot(product.createTime) }}</div>
                                                </div>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </a>
                            </div>
                            <div style="height: 50px; text-align: center">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :page-size="pageSize"
                                    :pager-count="11"
                                    layout="prev, pager, next"
                                    :total="total"
                                ></el-pagination>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <!-- 手机版页面  -->
        <div id="productListDiv" v-if="this.GLOBAL.isMobile" v-on:touchmove="isShow">
            <div style="min-height: 800px;background-color: #f5f5f7;">
                <div style="background-color: #ffffff; padding: 80px 10px 10px;">
                    <el-input :placeholder="$t('text1')" v-model="searchValue" class="input-with-select">
                        <el-button slot="append"
                                   icon="el-icon-search"
                                   @click="initPageList()"
                        ></el-button>
                    </el-input>
                </div>
                <div>

                    <a v-for="( product, index) in productPageList" @click="$router.push({ path: '/news/detail', query: { id: product.id} })" style="text-decoration:none">
                        <div v-if="index === 0" style="margin: 3%; background-color: #ffffff; border-radius: 5px; overflow: hidden;">
                            <div style="width: 100%;position: relative;">
                                <div style="line-height: 0">
                                    <img alt="" :src="[cp + '/jyy/billFile/getFile?uuid=' + product.titlePicture]"
                                         style="border-radius: 5px;width: 100%;"/>
                                </div>
                                <div style="background-color: rgba(255,255,255,0.7);position:absolute;width: 100%; height: 60px; bottom:0">
                                    <div style="padding-left:10px;margin-top: 1%; color:#000;font-weight: bold;font-size: 16px;">
                                        {{ product.newsTitle }}
                                    </div>
                                    <div style="padding-left:10px;margin-top: 1%; color: #000;font-size: 14px; white-space: nowrap;  display: block;  overflow: hidden;  text-overflow: ellipsis;">
                                        {{ product.newsDigest }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="index !== 0" style="padding: 2%;margin: 3%;background-color: #ffffff; border-radius: 5px; border: 1px solid #ffffff;">
                            <div style="width: 100%;display: flex;">
                                <div style="width: 30%;position: relative;">
                                    <img alt="" :src="[cp + '/jyy/billFile/getFile?uuid=' + product.titlePicture]"
                                         style="border-radius: 5px;max-width: 100%;max-height: 100%;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);"/>
                                </div>
                                <div style="width: 70%; padding-left: 4%;">
                                    <div style="  color:#000;font-weight: bold;  font-size: 16px;">
                                        {{ product.newsTitle }}
                                    </div>
                                    <div style=" margin-top: 1%; color: #000;font-size: 14px; white-space: nowrap;  display: block;  overflow: hidden;  text-overflow: ellipsis;">
                                        {{ product.newsDigest }}
                                    </div>
                                    <div style=" color: #646464;font-size: 12px; white-space: nowrap;  display: block;  overflow: hidden;  text-overflow: ellipsis;">
                                        {{ shortTimeDot(product.createTime) }}
                                    </div>
                                    <div style="flex: 1"></div>
                                </div>
                            </div>
                        </div>
                    </a>


                </div>
                <div id="getMoreDiv" style="padding-bottom: 100px">
                    <div v-if="total > page * pageSize" style="text-align: center">
                        {{ $t('text2') }}
                    </div>
                </div>

            </div>


        </div>
    </div>
</template>

<script>
import {getPageParamStr, getParam} from '@/utils/utils'
import axios from '@/utils/axios';
import index from "vuex";
import {dayDot, monthTimeDot, shortTime, shortTimeDot} from "@/utils/dateFormat";

export default {
    computed: {
        index() {
            return index
        }
    },
    data() {
        return {
            cp: this.GLOBAL.cp,
            page: 1,
            pageSize: 10,
            total: 0,
            searchValue: '',
            newsType: '',
            productPageList: [],
        }
    },
    watch: {
        $route(val) {
            this.newsType = getParam('type')
            this.initPageList()
        },
    },
    mounted() {
        this.newsType = getParam('type')
        this.initPageList()
    },
    methods: {
        shortTimeDot,
        dayDot,
        monthTimeDot,
        shortTime,
        initPageList() {
            let searchValue = encodeURIComponent(this.searchValue)
            let url = '/jyy/portal/selectNewsPage?searchValue=' + searchValue +
                '&newsType=' + this.newsType +
                getPageParamStr(this.page, this.pageSize)
            axios.get(url)
                .then(res => {
                    this.page = res.data.page
                    this.pageSize = res.data.pageSize
                    this.total = res.data.total
                    if (this.GLOBAL.isMobile) {
                        this.productPageList = this.productPageList.concat(res.data.rows)
                    } else {
                        this.productPageList = res.data.rows
                    }
                })
        },
        handleSizeChange(val) {
            this.pageSize = val
            this.initPageList()
        },
        handleCurrentChange(val) {
            this.page = val
            this.initPageList()
        },
        isShow() {
            const element = document.getElementById("getMoreDiv");
            const innerHeight = window.innerHeight;
            const clientHeight = element.clientHeight;
            // console.log(innerHeight)
            // console.log(clientHeight)
            const {top, right, bottom, left} = element.getBoundingClientRect();
            // console.log(innerHeight, clientHeight, top, right, bottom, left)
            if (top < innerHeight - clientHeight + 100 && !this.loading && this.total > this.page * this.pageSize) {
                this.page = this.page + 1
                this.initPageList()
            }
        },
    },
}
</script>

<style lang="less" scoped>


.hoverColor {
    color: black;
}

.hoverColor:hover {
    color: #d10a29;
}

.qqcom-recIcon {
    display: inline-block;
    margin-left: 6px;
    height: 18px;
    vertical-align: baseline
}

.rightDate {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 150px;
    border-left: 1px solid #e4e4e4;
    margin: 50px;
}

</style>
