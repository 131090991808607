function getParam(name) {
    var url = window.location.href;
    var urlOne = url.split('#')[1];
    var urlParam = urlOne.split('?')[1];
    if (urlParam) {
        var paramArr = urlParam.split('&');
        var param = {};
        for (var i = 0; i < paramArr.length; i++) {
            param[paramArr[i].split('=')[0]] = paramArr[i].split('=')[1]
        }
        if (param[name]) {
            return param[name];
        }
    }
    return '';
}

function getPageParamStr(page, pageSize) {
    return "&page=" + page + "&rows=" + pageSize
}


function formatMoney(value) {
    let result;
    // 在这里进行格式化，大于一万的数字显示为 "xx万"
    if (value >= 100000000) {
        result = (value / 100000000).toFixed(0) + '亿';
    } else if (value >= 10000000) {
        result = (value / 10000000).toFixed(0) + '千万';
    } else if (value >= 1000000) {
        result = (value / 1000000).toFixed(0) + '百万';
    } else if (value >= 10000) {
        result = (value / 10000).toFixed(1) + '万';
    } else {
        result = value;
    }
    result = result.toString()
    // 去掉整数部分的小数点
    if (result.indexOf('.0') > -1) {
        result = result.replace('.0', '');
    }
    return result;
}

function getRandomColor() {
    // 生成RGB值在100到200之间的颜色，避免颜色太亮或太暗
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);
    // 转换为十六进制格式
    return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
}


export {
    getParam, getPageParamStr, formatMoney, getRandomColor
}
