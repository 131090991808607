<template>
    <div class="main home" style="min-height: 800px">
        <div v-if="this.GLOBAL.isMobile" style="height: 40px"></div>
        <div class="editor-content-view" :style="this.GLOBAL.isMobile? 'width: 96%': 'width: 60%'" v-if="companyNews" v-html="companyNews.newsContent"></div>
        <div style=""></div>
        <div style=""></div>
    </div>
</template>

<script>
import axios from '@/utils/axios'
import {getParam} from '@/utils/utils'

export default {
    data() {
        return {
            type: null,
            companyNews: null,
        }
    },
    watch: {
        $route(val) {
            this.getPageInfo()
        },
    },
    methods: {
        getPageInfo() {
            this.type = getParam('type')
            axios
                .get('/jyy/portal/getCompanyNewsByType?type=' + this.type)
                .then(res => {
                    if (res.data.code === 0) {
                        this.companyNews = res.data.data
                    } else {
                        this.$message({
                            showClose: true,
                            message: res.data.msg,
                            type: 'error',
                        })
                    }
                })
        },
    },
    mounted() {
        this.getPageInfo()
    },
}
</script>

<style lang="less" scoped></style>
