import { render, staticRenderFns } from "./sh.vue?vue&type=template&id=72e48698&scoped=true&"
import script from "./sh.vue?vue&type=script&lang=js&"
export * from "./sh.vue?vue&type=script&lang=js&"
import style0 from "./sh.vue?vue&type=style&index=0&id=72e48698&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72e48698",
  null
  
)

export default component.exports