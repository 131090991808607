<template>
  <div>
    <canvas id="bar-chart"></canvas>
  </div>
</template>

<script>

import ChartDataLabels from 'chartjs-plugin-datalabels';
import Chart from 'chart.js/auto'
Chart.register(ChartDataLabels);

export default {
  name: 'BarChart',
  props: {
    data: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      chart: null,
      ctx: null,
    };
  },
  mounted() {
    this.ctx = document.getElementById('bar-chart');
    this.chart = new Chart(this.ctx, {
      type: 'bar',
      data: this.data,
      options: this.options
    })
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        if (this.chart) {
          this.chart.destroy();
        }
        this.chart = new Chart(this.ctx, {
          type: 'bar',
          data: this.data,
          options: this.options
        })
      },
      deep: true
    }
  }
}
</script>

<style scoped>
canvas {
  max-width: 100%;
  min-height: 220px;
  max-height: 400px;
}
</style>
