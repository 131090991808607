<template>
    <div id="___gatsby">
        <div tabindex="-1" id="gatsby-focus-wrapper" style="outline: none">
            <div>
                <div class="header">
                    <NavBar v-if="!this.GLOBAL.isMobile"></NavBar>
                    <MobileTopNavBar v-if="this.GLOBAL.isMobile"></MobileTopNavBar>
                </div>
                <router-view></router-view>
                <IndexFooter v-if="!this.GLOBAL.isMobile"></IndexFooter>
                <MobileNavBar  v-if="this.GLOBAL.isMobile"></MobileNavBar>
            </div>
        </div>
    </div>
</template>

<script>
import IndexFooter from '../components/indexfooter.vue'
import MobileNavBar from '../components/mobileNavBar.vue'
import NavBar from '../components/navBar.vue'
import MobileTopNavBar from "@/components/mobileTopNavBar.vue";

export default {
    components: {
        NavBar,
        IndexFooter,
        MobileTopNavBar,
        MobileNavBar,
    },
}
</script>

<style lang="less" scoped>



</style>
