import device from 'current-device'

const cp = process.env.VUE_APP_API_URL; // 后台地址
const isMobile = device.mobile()
const isIos = device.ios()

export default {
    cp, isMobile, isIos
}

