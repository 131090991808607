<template>
    <div class="bottom-bar">
        <router-link to="/home" class="bottom-bar-item" :class="{ active: currentUrl === 'home' }">
            <div class="bottom-bar-icon">
                <img class="iconImg" v-if="currentUrl !== 'home'" src="../assets/mobile_menu_home.svg"/>
                <img class="iconImg" v-if="currentUrl === 'home'" src="../assets/mobile_menu_home_fill.svg"/>
            </div>
            <div class="bottom-bar-text">{{ $t('mobile_foot1') }}</div>
        </router-link>
        <router-link v-if="productTypeList" :to="'/productList?type=' + productTypeList[0].id" class="bottom-bar-item" :class="{ active: currentUrl === 'product' }">
            <div class="bottom-bar-icon">
                <img class="iconImg" v-if="currentUrl !== 'product'" src="../assets/mobile_menu_product.svg"/>
                <img class="iconImg" v-if="currentUrl === 'product'" src="../assets/mobile_menu_product_fill.svg"/>
            </div>
            <div class="bottom-bar-text">{{ $t('mobile_foot2') }}</div>
        </router-link>
        <div class="bottom-bar-item">
            <a href="tel:182-1008-6720" style="position: revert;">
                <div data-v-2b525b20="" class="bottom-bar-tel"><img class="iconTel" src="../assets/mobile_menu_tel.svg"/></div>
                <div class="menu__border"></div>
                <div class="menu__border2"></div>
            </a>
        </div>
        <router-link to="/news" class="bottom-bar-item" :class="{ active: currentUrl === 'news' }">
            <div class="bottom-bar-icon">
                <img class="iconImg" v-if="currentUrl !== 'news'" src="../assets/mobile_menu_news.svg"/>
                <img class="iconImg" v-if="currentUrl === 'news'" src="../assets/mobile_menu_news_fill.svg"/>
            </div>
            <div class="bottom-bar-text">{{ $t('mobile_foot3') }}</div>
        </router-link>
        <router-link to="/fileDownloadList" class="bottom-bar-item" :class="{ active: currentUrl === 'file' }">
            <div class="bottom-bar-icon">
                <img class="iconImg" v-if="currentUrl !== 'file'" src="../assets/mobile_menu_file.svg"/>
                <img class="iconImg" v-if="currentUrl === 'file'" src="../assets/mobile_menu_file_fill.svg"/>
            </div>
            <div class="bottom-bar-text">{{ $t('mobile_foot4') }}</div>
        </router-link>
        <div class="svg-container">
            <svg viewBox="0 0 202.9 45.5">
                <clipPath id="menu" clipPathUnits="objectBoundingBox" transform="scale(0.0049285362247413 0.021978021978022)">
                    <path d="M6.7,45.5c5.7,0.1,14.1-0.4,23.3-4c5.7-2.3,9.9-5,18.1-10.5c10.7-7.1,11.8-9.2,20.6-14.3c5-2.9,9.2-5.2,15.2-7
          c7.1-2.1,13.3-2.3,17.6-2.1c4.2-0.2,10.5,0.1,17.6,2.1c6.1,1.8,10.2,4.1,15.2,7c8.8,5,9.9,7.1,20.6,14.3c8.3,5.5,12.4,8.2,18.1,10.5
          c9.2,3.6,17.6,4.2,23.3,4H6.7z"></path>
                </clipPath>
            </svg>
        </div>
    </div>
</template>


<script>

import axios from "@/utils/axios";

export default {
    name: 'MobileNavBar',
    data() {
        return {
            cp: this.GLOBAL.cp,
            productTypeList: null,
            goodsTypeList: null,
            newTypeList: null,
            currentUrl: null,
        }
    }, watch: {
        $route(to, from) {
            this.formatPath()
        },
    },
    mounted() {
        axios.get(this.cp + '/jyy/portal/getProductTypeList')
            .then(res => {
                this.productTypeList = res.data
            })
        this.formatPath()
    },
    methods: {
        formatPath() {
            let path = this.$router.currentRoute.path;
            if (path.indexOf("home") !== -1) {
                this.currentUrl = "home";
            } else if (path.indexOf("product") !== -1) {
                this.currentUrl = "product";
            } else if (path.indexOf("news") !== -1) {
                this.currentUrl = "news";
            } else if (path.indexOf("file") !== -1) {
                this.currentUrl = "file";
            }
        },

    }

}
</script>


<style scoped>

.bottom-bar {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    background-color: #fff;
    border-top: 1px solid #eee;
    z-index: 100;
    box-sizing: border-box;
    padding-bottom: 20px;
}

.bottom-bar-item {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 14px;
}

.bottom-bar-item.active {
    color: #d10a29;
}


.iconImg {
    width: 20px;
}

.bottom-bar-tel {
    position: absolute;
    left: calc(50% - 30px);
    z-index: 102;
    top: -10px;
    margin-bottom: 30px;
    width: 60px;
    height: 60px;
    padding: 5px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ddd;
}


.iconTel {
    width: 60px;

}

.menu__border {
    left: calc(50% - 60px);
    bottom: 79px;
    width: 120px;
    height: 18px;
    position: absolute;
    clip-path: url(#menu);
    background-color: #fff;
    z-index: 101;
}

.menu__border2 {
    left: calc(50% - 61px);
    bottom: 80px;
    width: 122px;
    height: 18px;
    position: absolute;
    clip-path: url(#menu);
    background-color: #eee;
    z-index: 100;
}

.svg-container {
    width: 0;
    height: 0;
}
</style>