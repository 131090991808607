<template>
    <nav id="header">
        <div style="margin-left: 10px;margin-right: 10px;width: auto;position: relative;">
            <a class="mobile_logo" href="#/home"><img src="../assets/icon_hope_deer.png" width="100px"/></a>
            <a class="telNo" href="tel:182-1008-6720">
                <strong style="font-size: 1.15rem;margin-right: 5px">&nbsp;182-1008-6720</strong>
                <img style="vertical-align: -20%" src="../assets/mobile_menu_tel.svg" width="25px"/>
            </a>
        </div>
    </nav>
</template>

<script>
import axios from '@/utils/axios'

export default {
    name: 'MobileTopNavBar',
    data() {
        return {
            productTypeList: null,
            newTypeList: null,
        }
    },
    mounted() {
    },
}
</script>

<style scoped>

#header {
    height: 60px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 55;
    width: 100%;
    background-color: #fff;

}

.mobile_logo {
    position: relative;
    float: left;
    display: inline-block;
    z-index: 5;
    padding: 15px 0;
    white-space: nowrap;
    padding-right: 2vw;
}

.telNo {
    display: inline-block;
    float: right;
    cursor: pointer;
    text-align: center;
    margin-top: 22px;
    color: #0a0a0a;
}
</style>
