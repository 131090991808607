import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/index.vue'
import ProductListView from '../views/product/productList.vue'
import ProductDetailView from '../views/product/productDeatil.vue'
import CompanyInfoView from '../views/company/companyInfo.vue'
import FileDownloadListView from '../views/fileDownload/fileDownloadList.vue'
import ItemView from '../views/consultation/item.vue'
import ShView from '../views/consultation/sh.vue'
import NewsListView from '../views/new/list.vue'
import NewsDetailView from '../views/new/detail.vue'
import DpView from '../views/dp/dp.vue'


import LayoutView from '../layout/index.vue'

Vue.use(VueRouter)
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [
    {
        path: '/',
        redirect: '/home',
        component: LayoutView,
        children: [
            {
                path: '/home',
                name: 'home',
                // route level code-splitting
                // this generates a separate chunk (about.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: HomeView
            },
            {
                path: '/productList',
                name: 'productList',
                component: ProductListView
            },
            {
                path: '/productDetail',
                name: 'productDetail',
                component: ProductDetailView
            },
            {
                path: '/companyInfo',
                name: 'companyInfo',
                component: CompanyInfoView
            },
            {
                path: '/fileDownloadList',
                name: 'fileDownloadList',
                component: FileDownloadListView
            },
            {
                path: '/item',
                name: 'item',
                component: ItemView
            },
            {
                path: '/sh',
                name: 'sh',
                component: ShView
            },
            {
                path: '/news',
                name: 'news',
                component: NewsListView
            },
            {
                path: '/news/detail',
                name: 'newsDetail',
                component: NewsDetailView
            },
        ]
    },
    {
        // 把后台的大屏搬到门户，方便手机端访问
        path: '/dp',
        name: '/dp',
        component: DpView,
    },
]

const router = new VueRouter({
    routes,
    scrollBehavior: () => ({ y: 0 }), //滚动到顶端,
})

export default router
