// cn.js
const RU = {
    type: "RU",
    lang: "Русский язык",

    // 首页-页眉字符
    index_1: "Домой",
    index_2: "О нас",
    index_3: "Введение",
    index_4: "Честь",
    index_5: "Культура",
    index_6: "Продукты",
    index_7: "Информация",
    index_8: "Услуги",
    index_9: "Сотрудничество",
    index_10: "После продажи",
    index_11: "Скачать",
    index_12: "Телефон",
    index_13: "",

    // 首页-主体字符
    index_21: "Последние новости",
    index_22: "Новейшие продукты",
    index_23: "Свяжитесь с нами",
    index_24: "Телефон：182-1008-6720",
    index_24_1: "Телефон：182-1008-6720",
    index_25: "Почтовый ящик：zhanghao@hopedeer.com",
    index_25_1: "Почтовый ящик：zhanghao@hopedeer.com",
    index_26: "Адрес：Шахэ, Чанпинский район, Пекин",
    index_27: "",
    index_27_1: "Адрес：",
    index_28: "Baisha Road Пекинский центр развития электронной науки и техники Xinfeida F4 - 4",
    index_28_1: "Baisha Road Пекинский центр развития электронной науки и техники Xinfeida F4 - 4",
    index_29: "Открыть навигацию",
    index_30: "Выберите навигационное программное обеспечение",
    index_31: "AMAP",
    index_32: "Tencent Maps",
    index_33: "Baidu map",

    index_34: "Beijing Hope Deer Technology Co. Ltd. была основана в 2021 году и имеет штаб - квартиру в Центре развития электронной науки и техники Xinfeida в районе Чанпин, Пекин, Китай F4 - 4. Наш отдел занимается исследованиями и разработками в области перфторполимерных воздухопроницаемых пленок, онлайн - дегазации растворителей, односторонних клапанов, соединений и других областей, в настоящее время запущен ряд прецизионных продуктов.",
    index_35_1: "① Перфторполимерная воздухопроницаемая пленка",
    index_35_1_1: "Перфторполимерная воздухопроницаемая пленка представляет собой новый тип тонкопленочного материала, который имеет высокую воздухопроницаемость, высокую прозрачность, термопластичность, самую высокую химическую инерцию среди всех тонкопленочных материалов, выдерживает коррозию практически всеми химическими веществами и обладает уникальными свойствами, которые не могут сравниться с другими пластиковыми пленочными материалами. Перфторполимерные воздухопроницаемые мембраны, как и материалы Teflon AF2400, относятся к неструктурированным перфторированным смолам, которые могут заменить материалы Teflon AF2400 и широко используются в таких областях, как газожидкостное разделение и онлайновая дегазация растворителей. Поддерживает настройку различных размеров.",
    index_35_2: "② мембранный дегазатор, мембранный дегазато",
    index_35_2_1: "Специально сконструированные полости, изготовленные из новых перфторполимерных пленочных материалов и других новых материалов, таких как ПЭЭК, при прохождении жидкости через полость, при отрицательном давлении вакуумного насоса, пропускают пузырьки воздуха из жидкости через пленку и выводят их, обеспечивая онлайновую дегазацию химических или биологических растворителей с высокой эффективностью дегазации, основные показатели намного превосходят традиционные трубчатые дегазаторы",
    index_35_3: "③ Серия односторонних клапано",
    index_35_3_1: "Для односторонней доставки всех видов реагентов, их герметичность, химическая совместимость, высокая надежность, с импортной группой шаров / сидений, можно выбрать рубин, сапфир, глиноземную керамику, циркониевую керамику, нитридную кремниевую керамику и другие материалы, обладают высокой износостойкостью, химической совместимостью, биологической инерцией, в то время как шар и шаровое сиденье используют процесс шлифования один на один, чтобы обеспечить отличную герметичность. В то же время внутренняя опционная пружина может эффективно предотвратить явление сифонии жидкости при остановке насоса",
    index_35_4: "④ Серия трубопроводо",
    index_35_4_1: "Прецизионный процесс обработки машины, поддерживающий различные внешние диаметры PEEK, PTFE, FEP, ETFE, PFA и другие инженерные пластиковые трубопроводы соединения, с более высокой точностью размера и более высокой плотностью материала",
    index_35_99: "Мы тепло приветствуем партнеров из всех слоев общества, чтобы совместно открыть новую главу в развитии науки и техники. Если у вас есть какие - либо вопросы или намерение сотрудничать с нашими продуктами или услугами, пожалуйста, свяжитесь с нами в любое время. Телефон 18210086720.",


    index_36: "Технический потенциал",
    index_37: "Компания запустила вакуумный дегазатор, наномембранный дегазационный бункер, односторонний клапан, штуцер прецизионной обработки, двухходовой разъем, прецизионный шприц и другие типы продуктов. ",
    index_38: "Успешные случаи",
    index_39: "Благодаря отличному обслуживанию и отличному качеству, он получил высокую оценку пользователей!  Кроме того, компания имеет эффективную исследовательскую и опытно - конструкторскую команду и сложное оборудование для шлифования и полировки, которое может быть быстро настроено в соответствии с потребностями пользователей и предлагает комплексные решения для жидких компонентов. ",
    index_40: "Посмотреть последние события",
    index_41: "Посмотреть",
    index_42: "Узнать больше",

    // 首页-页脚字符
    foot_1: "Почему выбирают нас",
    foot_2: "Новейшие продукты",
    foot_3: "Доступ к последней информации",
    foot_4: "Спасибо за внимание.",
    foot_5: "Да, надеюсь связаться со мной, чтобы получить новостной бюллетень, продвижение и мероприятия.",
    foot_6: "Представлено",
    foot_7: "Микрофон Public",
    foot_8: "Мобильная страница",
    foot_9: "Адрес почтового ящика не может быть пустым",
    foot_10: "Адрес почтового ящика не соответствует нормам",
    foot_11: "Пожалуйста, выберите пользовательское соглашение.",

    // 手机底部导航栏
    mobile_foot1: "Домой",
    mobile_foot2: "Продукты",
    mobile_foot3: "Информация",
    mobile_foot4: "Скачать",

    // 其他页面
    text1: "Введите содержимое",
    text2: "Загружается...",
    text3: "Консультации",
    text4: "Название компании",
    text5: "Название продукта",
    text6: "Контактная информация",
    text7: "Примечания",
    text8: "Поддерживая телефоны, почтовые ящики, стационарные телефоны и другие контактные данные, мы свяжемся с вами в первый раз",
    text9: "Выберите язык",
    text10: "Подробности продукции",
    text11: "Продуктовый документ",
    text12: "тип файла:",

}
export default RU
