import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementUI from 'element-ui';
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import './style/index.less'
import global from './config/global'
import i18n from './utils/lang/index'



// import 'tailwindcss/tailwind.css';
// import VueAMap from 'vue-amap';
// Vue.use(VueAMap);
// VueAMap.initAMapApiLoader({
//   //申请地址 https://lbs.amap.com/ 选择web端jsAPI
//   key: 'bbc8cd9ff57e464d4585d3a8466d6be2',
//   // 插件集合，用到什么插件就使用什么插件
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor'],
//   v: '1.4.4', // 高德sdk版本，最新的sdk已经出到了2.0
//   //官网解释：JSAPI 2.0 提供的开发接口与 1.4 版本达到 99%的兼容度，但是为了保证插件的稳定性我们还是选择1.4.4。
// })


Vue.use(ElementUI);
Vue.use(VueI18n)
Vue.config.productionTip = false
Vue.prototype.GLOBAL = global;

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: h => h(App)
})
