<template>
  <div class="" style="min-height: 800px;padding: 10px">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">销售额</span>
      </div>
      <el-table :data="custOrderData" max-height="280" border show-summary :summary-method="getCustSummaries" style="width: 100%">
        <el-table-column prop="v1" label="客户名称"></el-table-column>
        <el-table-column prop="v2" label="订单金额"></el-table-column>
        <el-table-column prop="v3" label="回款金额"></el-table-column>
      </el-table>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">月度销售额曲线</span>
      </div>
      <div class="statistics-report">
        <LineChart :data="monthOrderData.data" :options="monthOrderData.options" :chartId="'monthOrderDataChart'"/>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">产品月度销售曲线</span>
      </div>
      <div class="statistics-report">
        <LineChart :data="productMonthData.data" :options="productMonthData.options" :chartId="'productMonthDataChart'"/>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">产品销售总额排行</span>
      </div>
      <div class="statistics-report">
        <BarChart :data="productData.data" :options="productData.options" style="height: 250px"/>
      </div>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">销售明细</span>
      </div>
      <el-table :data="orderDetails" max-height="250" border style="width: 100%">
        <el-table-column prop="" label="客户名称">
          <template slot-scope="scope">
            {{ scope.row.custName }}
            <br>
            <el-tag class="status-tag" size="mini" type="success">{{ scope.row.createTime }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="productName" label="商品"></el-table-column>
        <el-table-column label="采购信息">
          <template slot-scope="scope">
            <span>
              <p>单价: {{ scope.row.price }}</p>
              <p>数量: {{ scope.row.purchaseNum }}</p>
              <p>金额: {{ scope.row.amount }}</p>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">支出事项</span>
        <span class="box-card-right-button" type="text">
          <el-radio-group v-model="payRecordType" size="mini" fill="#d10a29">
            <el-radio-button label="STATISTICS">统计</el-radio-button>
            <el-radio-button label="DETAILS">明细</el-radio-button>
          </el-radio-group>
        </span>
      </div>

      <LineChart v-if="payRecordType === 'STATISTICS'" :data="payRecordStatistics.data" :options="payRecordStatistics.options" :chartId="'payRecordStatisticsChart'" style="height: 250px"/>

      <el-table v-if="payRecordType === 'DETAILS'" :data="payRecords" max-height="250" border style="width: 100%">
        <el-table-column prop="" label="支出事项">
          <template slot-scope="scope">
            {{ scope.row.reason }}
            <br>
            <el-tag v-if="scope.row.payTime" class="status-tag" size="mini" type="success">{{ scope.row.payTime }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="采购信息">
          <template slot-scope="scope">
            <span>
              <p v-if="scope.row.num">数量: {{ scope.row.num }}</p>
              <p v-if="scope.row.price">单价: {{ scope.row.price }}</p>
              <p v-if="scope.row.amount">金额: {{ scope.row.amount }}</p>
              <p v-if="scope.row.payPerson">支出人: {{ scope.row.payPerson }}</p>
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注"></el-table-column>
      </el-table>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">产品试用情况</span>
      </div>
      <el-table :data="productTrialList" max-height="260" border style="width: 100%">
        <el-table-column label="客户名称">
          <template slot-scope="scope">
            <p>{{ scope.row.custName }}</p>
            <p v-if="scope.row.isReturnBack == '1'">
              <el-tag class="status-tag" size="mini" type="success">已全部归还</el-tag>
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="" label="测试方案">
          <template slot-scope="scope">
            {{ scope.row.testPlan }}
            <p style="background-color: #fafafa" v-if="scope.row.startDate && scope.row.endDate">期限: {{ scope.row.startDate }}至{{ scope.row.endDate }}</p>
          </template>
        </el-table-column>
        <el-table-column label="跟进情况">
          <template slot-scope="scope">
            <span>
              <p v-if="scope.row.followSituation">{{ scope.row.followSituation }}</p>
              <p v-if="scope.row.remark">{{ scope.row.remark }}</p>
            </span>
          </template>
        </el-table-column>

      </el-table>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">企业列表</span>
        <span class="box-card-right-button" type="text">
          <el-radio-group v-model="custType" size="mini" fill="#d10a29">
            <el-radio-button label="KH">客户{{ custListKH.length }}</el-radio-button>
            <el-radio-button label="KHFZ">发展中{{ custListKHFZ.length }}</el-radio-button>
            <el-radio-button label="GYS">供应商{{ custListGYS.length }}</el-radio-button>
          </el-radio-group>
        </span>
      </div>
      <el-table v-if="custType === 'KH'" :data="custListKH" max-height="260" border style="width: 100%">
        <el-table-column prop="name" label="企业名称"></el-table-column>
        <el-table-column label="联络信息">
          <template slot-scope="scope">
            <p v-if="scope.row.contactsName || scope.row.contactsTel">
              联系人：
              <span v-if="scope.row.contactsName">{{ scope.row.contactsName }}</span>
              <a v-if="scope.row.contactsTel" :href="'tel:' + scope.row.contactsTel">
                <span style="color: #00a500">{{ scope.row.contactsTel }}</span>
              </a>
            </p>
            <p v-if="scope.row.devName || scope.row.devPhone">
              研发：
              <span v-if="scope.row.devName">{{ scope.row.devName }}</span>
              <a v-if="scope.row.devPhone" :href="'tel:' + scope.row.devPhone">
                <span style="color: #00a500">{{ scope.row.devPhone }}</span>
              </a>
            </p>
            <p v-if="scope.row.purchaseName || scope.row.purchasePhone">
              采购：
              <span v-if="scope.row.purchaseName">{{ scope.row.purchaseName }}</span>
              <a v-if="scope.row.purchasePhone" :href="'tel:' + scope.row.purchasePhone">
                <span style="color: #00a500">{{ scope.row.purchasePhone }}</span>
              </a>
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="custType === 'KHFZ'" :data="custListKHFZ" max-height="260" border style="width: 100%">
        <el-table-column prop="name" label="企业名称"></el-table-column>
        <el-table-column label="联络信息">
          <template slot-scope="scope">
            <p v-if="scope.row.contactsName || scope.row.contactsTel">
              联系人：
              <span v-if="scope.row.contactsName">{{ scope.row.contactsName }}</span>
              <a v-if="scope.row.contactsTel" :href="'tel:' + scope.row.contactsTel">
                <span style="color: #00a500">{{ scope.row.contactsTel }}</span>
              </a>
            </p>
            <p v-if="scope.row.devName || scope.row.devPhone">
              研发：
              <span v-if="scope.row.devName">{{ scope.row.devName }}</span>
              <a v-if="scope.row.devPhone" :href="'tel:' + scope.row.devPhone">
                <span style="color: #00a500">{{ scope.row.devPhone }}</span>
              </a>
            </p>
            <p v-if="scope.row.purchaseName || scope.row.purchasePhone">
              采购：
              <span v-if="scope.row.purchaseName">{{ scope.row.purchaseName }}</span>
              <a v-if="scope.row.purchasePhone" :href="'tel:' + scope.row.purchasePhone">
                <span style="color: #00a500">{{ scope.row.purchasePhone }}</span>
              </a>
            </p>
          </template>
        </el-table-column>
      </el-table>
      <el-table v-if="custType === 'GYS'" :data="custListGYS" max-height="260" border style="width: 100%">
        <el-table-column prop="name" label="企业名称"></el-table-column>
        <el-table-column label="联络信息">
          <template slot-scope="scope">
            <p v-if="scope.row.contactsName || scope.row.contactsTel">
              联系人：
              <span v-if="scope.row.contactsName">{{ scope.row.contactsName }}</span>
              <a v-if="scope.row.contactsTel" :href="'tel:' + scope.row.contactsTel">
                <span style="color: #00a500">{{ scope.row.contactsTel }}</span>
              </a>
            </p>
            <p v-if="scope.row.devName || scope.row.devPhone">
              研发：
              <span v-if="scope.row.devName">{{ scope.row.devName }}</span>
              <a v-if="scope.row.devPhone" :href="'tel:' + scope.row.devPhone">
                <span style="color: #00a500">{{ scope.row.devPhone }}</span>
              </a>
            </p>
            <p v-if="scope.row.purchaseName || scope.row.purchasePhone">
              采购：
              <span v-if="scope.row.purchaseName">{{ scope.row.purchaseName }}</span>
              <a v-if="scope.row.purchasePhone" :href="'tel:' + scope.row.purchasePhone">
                <span style="color: #00a500">{{ scope.row.purchasePhone }}</span>
              </a>
            </p>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="box-card-title">原材料库存</span>
      </div>
      <el-table :data="materialStockQuantity" max-height="260" border show-summary :summary-method="getMaterialStockSummaries" style="width: 100%">
        <el-table-column label="名称">
          <template slot-scope="scope">
            <span v-if="scope.row.materialName">{{ scope.row.materialName }} </span>
            <span v-if="scope.row.materialModel">{{ scope.row.materialModel }} </span>
            <span v-if="scope.row.materialSpec">{{ scope.row.materialSpec }} </span>
          </template>
        </el-table-column>
        <el-table-column label="实时库存">
          <template slot-scope="scope">
            <span v-if="scope.row.quantity">{{ scope.row.quantity }}</span>
            <span v-if="scope.row.materialUnit">{{ scope.row.materialUnit }}</span>
          </template>
        </el-table-column>
        <el-table-column label="货值">
          <template slot-scope="scope">
            <span v-if="scope.row.amountTax">{{ scope.row.amountTax }}元</span>
            <!--              <span v-if="scope.row.amount">不含税：{{ scope.row.amount }}元</span>-->
          </template>
        </el-table-column>
      </el-table>

    </el-card>


  </div>
</template>

<script>
import axios from '@/utils/axios';
import index from "vuex";

import BarChart from "@/components/BarChart.vue";
import LineChart from "@/components/LineChart.vue";
import {formatMoney, getRandomColor} from "@/utils/utils";

export default {
  components: {BarChart, LineChart},
  computed: {
    index() {
      return index
    }
  },
  data() {
    return {
      cp: this.GLOBAL.cp,
      totalSaleAmount: 0,
      totalPayAmount: 0,
      custOrderData: [],
      orderDetails: [],
      monthOrderData: {
        data: {
          labels: [],
          datasets: [
            {
              label: '月度销售额',
              borderColor: '#f87979',
              backgroundColor: '#f87979',
              fill: false,
              cubicInterpolationMode: 'monotone',
              data: [],
              yAxisID: 'y1'
            },
            {
              label: '累计销售额',
              borderColor: '#36a2eb',
              backgroundColor: '#36a2eb',
              cubicInterpolationMode: 'monotone',
              fill: false,
              data: [],
              yAxisID: 'y2'
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y1: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.1; // 增加10%的空间
                scale.max += extra;
              }
            },
            y2: {
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false
              },
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.1; // 增加10%的空间
                scale.max += extra;
              }
            }
          },
          plugins: {
            legend: {
              position: 'top',
            },
            datalabels: {
              // display: false,
              anchor: 'top',
              align: 'top',
              color: '#757575',  // 标签的颜色
              formatter: function (value) {
                return formatMoney(value);  // 格式化显示的数值
              }
            },
          },
        }
      },
      productMonthData: {
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          plugins: {
            legend: {
              position: 'top',
            },
            datalabels: {
              display: true,
              anchor: 'top',
              align: 'top',
              color: '#757575',  // 标签的颜色
              formatter: function (value) {
                return formatMoney(value);  // 格式化显示的数值
              }
            },
          },
          scales: {
            y: {
              type: 'linear',
              display: true,
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.2; // 增加10%的空间
                scale.max += extra;
              }
            }
          }
        }
      },
      productData: {
        data: {
          labels: [],
          datasets: []
        },
        options: {
          // maxBarLength: 20,
          indexAxis: 'y',
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: '#757575',  // 标签的颜色
              formatter: function (value) {
                return formatMoney(value);  // 格式化显示的数值
              }
            }
          },
          scales: {
            x: {
              display: true,
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.5; // 增加10%的空间
                scale.max += extra;
              }
            }
          },
        }
      },
      payRecordType: 'STATISTICS',
      payRecords: [],
      payRecordStatistics: {
        data: {
          labels: [],
          datasets: [
            {
              label: '月度支出',
              borderColor: '#f87979',
              backgroundColor: '#f87979',
              fill: false,
              cubicInterpolationMode: 'monotone',
              data: [],
              yAxisID: 'y1'
            },
            {
              label: '累计支出',
              borderColor: '#36a2eb',
              backgroundColor: '#36a2eb',
              cubicInterpolationMode: 'monotone',
              fill: false,
              data: [],
              yAxisID: 'y2'
            }
          ]
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          interaction: {
            mode: 'index',
            intersect: false,
          },
          scales: {
            y1: {
              type: 'linear',
              display: true,
              position: 'left',
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.1; // 增加10%的空间
                scale.max += extra;
              }
            },
            y2: {
              type: 'linear',
              display: true,
              position: 'right',
              grid: {
                drawOnChartArea: false
              },
              ticks: {
                callback: function (value) {
                  return formatMoney(value);
                }
              },
              afterDataLimits(scale) {
                const range = scale.max - scale.min;
                const extra = range * 0.1; // 增加10%的空间
                scale.max += extra;
              }
            }
          },
          plugins: {
            legend: {
              position: 'top',
            },
            datalabels: {
              anchor: 'top',
              align: 'top',
              color: '#757575',  // 标签的颜色
              formatter: function (value) {
                return formatMoney(value);  // 格式化显示的数值
              }
            },
          },
        }
      },
      productTrialList: [],
      custType: 'KH',
      custListKH: [],
      custListKHFZ: [],
      custListGYS: [],
      materialStockQuantity: [],
    }
  },
  mounted() {
    this.checkCertificate()
  },
  methods: {
    formatMoney,
    checkCertificate() {
      const certificate = localStorage.getItem('certificate');
      console.log(certificate)
      if (certificate) {
        if (atob(certificate).includes("MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA2WuQ9zrEoGA5Lv9XW91+3SuJhGJ4osDFbxxSvf0S8bbCC54l+wU42PkPe4fxbeOjgioJWS26VLbQ+dGfs4ZtV/nVasUCsuaRqFOz/TaubZnzh2bXNAyx1ZXPs+C1QoRgCQpCj4LLzr4wFhuL0okmOiUZIks6MtrDDEfd7euIjHc1akvcVGo7KyKwiTCuBrkinWAeMo6gVG1v7msJ/pvpfqA3bhS37dZJm92bCKnoeWvinVu03EvLH6np8wOIGCgCqXl42R2sjmz2WPsc/YfEnhEjwpnG74PvJ9e7giAD0flUQwFP5RXgUg99pyb3+WUNb+q4ZDX0eRxlO1A5Eu9WFwIDAQAB")) {
          this.initChart()
        } else {
          this.checkPhone()
        }
      } else {
        this.checkPhone()
      }
    },
    checkPhone() {
      this.$prompt('请输入密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({value}) => {
        axios.get('/jyy/dp/check?value=' + btoa(value))
          .then(res => {
            if (res.data.code === 0) {
              localStorage.setItem('certificate', res.data.msg);
              this.initChart()
            } else {
              this.$message({
                type: 'error',
                message: res.data.msg,
                offset: 100,
              });
              setTimeout(() => {  // 使用箭头函数
                this.checkPhone();
              }, 800);
            }
          })
      }).catch(() => {
        this.$router.push('/');
      });
    },


    initChart() {
      this.getCustOrder()
      this.getProductMonthData()
      this.getProductData()
      this.getOrderDetail()
      this.getPayRecord()
      this.getProductTrialList()
      this.getCustList()
      this.getMaterialStockQuantity()
    },
    getCustSummaries(param) {
      return ['合计', formatMoney(this.totalSaleAmount), formatMoney(this.totalPayAmount)];
    },
    getMaterialStockSummaries(param) {
      const {columns, data} = param;
      let totalAmountTax = 0
      for (let i = 0; i < data.length; i++) {
        totalAmountTax += parseInt(data[i].amountTax)
      }
      return ['合计', '', formatMoney(totalAmountTax) + "元"];
    },
    getCustOrder() {
      axios.get('/jyy/dp/getCustOrder?num=100')
        .then(res => {
          this.monthOrderData.data.labels = res.data.monthOrderData.times
          this.monthOrderData.data.datasets[0].data = res.data.monthOrderData.monthData
          this.monthOrderData.data.datasets[1].data = res.data.monthOrderData.totalData
          this.custOrderData = res.data.custOrderData
          for (let i = 0; i < this.custOrderData.length; i++) {
            this.totalSaleAmount += parseInt(this.custOrderData[i]["v2"])
            this.totalPayAmount += parseInt(this.custOrderData[i]["v3"])
          }
        })
    },
    getProductMonthData() {
      axios.get('/jyy/dp/getProductMonthData?num=6')
        .then(res => {
          this.productMonthData.data.labels = res.data.productMonthData.month
          let productList = []
          for (let i = 0; i < res.data.productMonthData.monthData.length; i++) {
            let item = res.data.productMonthData.monthData[i]
            let color = getRandomColor()
            let dataItem = {label: item.name, data: item.data, borderColor: color, backgroundColor: color}
            productList.push(dataItem)
          }
          this.productMonthData.data.datasets = productList
        })
    },
    getProductData() {
      axios.get('/jyy/dp/getProductData?num=10')
        .then(res => {
          this.productData.data.labels = res.data.whData.catalog.reverse()
          this.productData.data.datasets = [{data: res.data.whData.weight.reverse()}]
        })
    },
    getOrderDetail() {
      axios.get('/jyy/dp/getOrderDetail?num=100')
        .then(res => {
          this.orderDetails = res.data.orderDetails
        })
    },
    getPayRecord() {
      axios.get('/jyy/dp/getPayRecord?num=100')
        .then(res => {
          this.payRecords = res.data.payRecords
        })
      axios.get('/jyy/dp/getPayRecordStatistics')
        .then(res => {
          this.payRecordStatistics.data.labels = res.data.times
          this.payRecordStatistics.data.datasets[0].data = res.data.monthData
          this.payRecordStatistics.data.datasets[1].data = res.data.totalData
        })
    },
    getProductTrialList() {
      axios.get('/jyy/dp/getProductTrialList?num=100')
        .then(res => {
          this.productTrialList = res.data.productTrialList
        })
    },
    getCustList() {
      axios.get('/jyy/dp/getCustList?custType=KH')
        .then(res => {
          this.custListKH = res.data.data
        })
      axios.get('/jyy/dp/getCustList?custType=KHFZ')
        .then(res => {
          this.custListKHFZ = res.data.data
        })
      axios.get('/jyy/dp/getCustList?custType=GYS')
        .then(res => {
          this.custListGYS = res.data.data
        })
    },
    getMaterialStockQuantity() {
      axios.get('/jyy/dp/getMaterialStockQuantity')
        .then(res => {
          this.materialStockQuantity = res.data.rows
        })
    },
  },
}
</script>

<style lang="less">

.el-message-box {
  width: 300px;
}

.box-card {
  margin-bottom: 10px;

  .el-card__header {
    padding: 10px 15px !important;
  }

  .el-card__body {
    padding: 10px !important;
  }

  .box-card-title {
    font-size: 18px
  }

  .box-card-right-text {
    float: right;
    margin: 5px
  }

  .box-card-right-button {
    float: right;
  }

  .el-table .el-table__cell {
    padding: 6px 0;
  }

}



.statistics-report {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
