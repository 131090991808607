// index.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)
// 引入各个语言配置文件
import cnLocale from './config/cn'
import enLocale from './config/en'
import ruLocale from './config/ru'

// 判断浏览器语言类型
let defaultLanguage = localStorage.getItem("lang");
if (defaultLanguage == null) {
    if (navigator.language.indexOf('en') !== -1 || navigator.language.indexOf('EN') !== -1) {
        defaultLanguage = 'EN'
    } else if (navigator.language.indexOf('ru') !== -1 || navigator.language.indexOf('RU') !== -1) {
        defaultLanguage = 'RU'
    } else {
        defaultLanguage = 'CN'
    }
    localStorage.setItem("lang", defaultLanguage);
}
console.log("初始化语言类型：", defaultLanguage)

// 创建vue-i18n实例i18n
const i18n = new VueI18n({
    // 设置默认语言
    locale: defaultLanguage,
    // 添加多语言（每一个语言标示对应一个语言文件）
    messages: {
        'CN': cnLocale,
        'EN': enLocale,
        'RU': ruLocale,
    }
})

// 暴露i18n
export default i18n

