// cn.js
const CN = {
    type: "CN",
    lang: "中文",

    // 首页-页眉字符
    index_1: "首页",
    index_2: "关于我们",
    index_3: "公司简介",
    index_4: "荣誉资质",
    index_5: "企业文化",
    index_6: "产品中心",
    index_7: "新闻资讯",
    index_8: "服务支持",
    index_9: "项目合作",
    index_10: "售后服务",
    index_11: "资料下载",
    index_12: "服务热线",
    index_13: "",

    // 首页-主体字符
    index_21: "最新动态",
    index_22: "最新产品",
    index_23: "联系我们",
    index_24: "业务咨询：182-1008-6720",
    index_24_1: "电话：182-1008-6720",
    index_25: "联系邮箱：zhanghao@hopedeer.com",
    index_25_1: "邮箱：zhanghao@hopedeer.com",
    index_26: "所在地区：北京市昌平区沙河镇",
    index_27: "详细地址：",
    index_27_1: "地址：",
    index_28: "百沙路北京新飞达电子科技工业发展中心F4-4",
    index_28_1: "北京新飞达电子科技工业发展中心F4-4",
    index_29: "打开导航",
    index_30: "请选择导航软件",
    index_31: "高德地图",
    index_32: "腾讯地图",
    index_33: "百度地图",

    index_34: "北京红麓科技有限公司（Beijing Hope Deer Technology Co., Ltd.）成立于2021年，总部位于中国北京市昌平区新飞达电子科技工业发展中心F4-4。我司致力于全氟聚合物透气膜、溶剂在线脱气、单向阀、接头等领域的研发，目前已推出多款精密产品。",
    index_35_1: "① 全氟聚合物透气膜",
    index_35_1_1: "全氟聚合物透气膜是一种新型薄膜材料，其透气率高，透明度高，具有热塑性，在所有薄膜材料中化学惰性最高，几乎可耐受一切化学品的腐蚀，具有其他塑料薄膜材料无法媲美的独特性能。全氟聚合物透气膜与Teflon AF2400材料一样，都属于非定型全氟树脂，可以替代Teflon AF2400材料，广泛应用于气液分离、溶剂在线脱气等领域。支持各类尺寸定制。",
    index_35_2: "② 膜式脱气机、膜式脱气腔",
    index_35_2_1: "利用新型全氟聚合物薄膜材料和PEEK等其他新型材料制成的特殊结构的腔体，当液体流经腔体时，在真空泵的负压作用下，将液体中的气泡透过薄膜并排出，实现对化学或生物溶剂的在线脱气，脱气效率高，主要指标远优于传统的管路式脱气机。",
    index_35_3: "③ 单向阀系列",
    index_35_3_1: "用于单向输送各类试剂，其密封性、耐化学兼容性、可靠性高，用进口球/座组，可选用红宝石、蓝宝石、氧化铝陶瓷、氧化锆陶瓷、氮化硅陶瓷等材质，均具有极高的耐磨性、耐化学兼容性、生物惰性，同时球与球座采用一对一配对研磨工艺，确保极佳的密封性。同时内部可选配弹簧，可以有效防止停泵时产生液体虹吸现象。",
    index_35_4: "④ 管路接头系列",
    index_35_4_1: "精密机加工工艺制成，支持各类外径的PEEK、PTFE、FEP、 ETFE、PFA等工程塑料管路的连接，具有更高的尺寸精度和更高的材料密度。",
    index_35_99: "我们热忱欢迎各界合作伙伴的加入，共同开创科技发展的新篇章。如果您对我们的产品或服务有任何疑问或合作意向，欢迎随时与我们联系。联系电话18210086720。",

    index_36: "技术实力",
    index_37: "公司先后推出的真空脱气机、纳米膜脱气仓、单向阀、精密机加工管接头、两通接头、精密注射器等类型产品。",
    index_38: "成功案例",
    index_39: "凭借优良的服务和过硬的品质，得到了用户的一致好评！此外，公司拥有高效的研发团队和精密的研磨抛光设备，可以根据用户需求实现快速定制，并提供流体类零部件的整体解决方案。",
    index_40: "查看红麓的最新动态",
    index_41: "查看",
    index_42: "了解更多",

    // 首页-页脚字符
    foot_1: "为什么选择红麓",
    foot_2: "最新产品",
    foot_3: "获取最新资讯",
    foot_4: "感谢您的关注",
    foot_5: "是的，希望红麓与我联系，以获取新闻通讯、促销和活动。",
    foot_6: "提交",
    foot_7: "官方微信",
    foot_8: "手机端",
    foot_9: "邮箱地址不能为空",
    foot_10: "邮箱地址不符合规范",
    foot_11: "请勾选用户协议",

    // 手机底部导航栏
    mobile_foot1: "首页",
    mobile_foot2: "产品",
    mobile_foot3: "资讯",
    mobile_foot4: "文档",

    // 其他页面
    text1: "请输入内容",
    text2: "正在加载...",
    text3: "立即咨询",
    text4: "公司名称",
    text5: "产品名称",
    text6: "联系方式",
    text7: "备注",
    text8: "支持手机、邮箱、固定电话等联系方式，我们会在第一时间联系您。",
    text9: "请选择语言",
    text10: "产品详情",
    text11: "产品文档",
    text12: "文件类型：",

}
export default CN
