<template>
    <div class="main home">
        <div class="w">
            <div class="title" >
                {{ news.newsTitle }}
            </div>
            <div class="editor-content-view" :style="this.GLOBAL.isMobile? 'width: 96%': 'width: 60%'" v-if="news" v-html="news.newsContent"></div>
        </div>
    </div>
</template>

<script>
import {getParam} from '@/utils/utils'
import axios from '@/utils/axios'

export default {
    data() {
        return {
            cp: this.GLOBAL.cp,
            news: {},
            companyNews: {},
        }
    },
    mounted() {
        axios
            .get('/jyy/portal/getNewsDetail?id=' + getParam('id'))
            .then(res => {
                this.news = res.data
            })
    },
}
</script>

<style lang="less" scoped>
.w {
    // width: 1440px;
    margin: 0 auto;
    padding: 100px 0;
    .title {
        font-size: 22px;
        text-align: center;
        padding: 0 20px;
        margin: 0;
    }

}
</style>
